import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Modal, ProgressBar } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BudgetPlanner.css';
import { Helmet } from 'react-helmet';

const BudgetPlanner = () => {
  const [incomeSources, setIncomeSources] = useState([{ description: '', amount: '' }]);
  const [expenseDescription, setExpenseDescription] = useState('');
  const [expenseAmount, setExpenseAmount] = useState('');
  const [expenseCategory, setExpenseCategory] = useState('Miscellaneous');
  const [isRecurring, setIsRecurring] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [savingsGoal, setSavingsGoal] = useState('');
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [balance, setBalance] = useState(0);
  const [editIndex, setEditIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleAddIncomeSource = () => {
    setIncomeSources([...incomeSources, { description: '', amount: '' }]);
  };

  const handleIncomeChange = (index, field, value) => {
    const updatedSources = [...incomeSources];
    updatedSources[index][field] = field === 'amount' ? parseFloat(value) : value;
    setIncomeSources(updatedSources);
    calculateTotals(updatedSources, expenses);
  };

  const handleAddExpense = () => {
    const expense = {
      description: expenseDescription,
      amount: parseFloat(expenseAmount),
      category: expenseCategory,
      recurring: isRecurring,
    };

    if (editIndex !== null) {
      const updatedExpenses = [...expenses];
      updatedExpenses[editIndex] = expense;
      setExpenses(updatedExpenses);
      setEditIndex(null);
    } else {
      setExpenses([...expenses, expense]);
    }

    setExpenseDescription('');
    setExpenseAmount('');
    setExpenseCategory('Miscellaneous');
    setIsRecurring(false);
    calculateTotals(incomeSources, [...expenses, expense]);
    setShowModal(false);
  };

  const handleEditExpense = (index) => {
    const expense = expenses[index];
    setExpenseDescription(expense.description);
    setExpenseAmount(expense.amount);
    setExpenseCategory(expense.category);
    setIsRecurring(expense.recurring);
    setEditIndex(index);
    setShowModal(true);
  };

  const handleDeleteExpense = (index) => {
    const updatedExpenses = expenses.filter((_, i) => i !== index);
    setExpenses(updatedExpenses);
    calculateTotals(incomeSources, updatedExpenses);
  };

  const calculateTotals = (updatedIncomeSources, updatedExpenses) => {
    const totalIncome = updatedIncomeSources.reduce((sum, source) => sum + (source.amount || 0), 0);
    const totalExpenses = updatedExpenses.reduce((sum, expense) => sum + (expense.amount || 0), 0);
    setTotalIncome(totalIncome);
    setTotalExpenses(totalExpenses);
    setBalance(totalIncome - totalExpenses);
  };

  const exportData = () => {
    const data = expenses.map(expense => ({
      Description: expense.description,
      Amount: expense.amount,
      Category: expense.category,
      Recurring: expense.recurring ? 'Yes' : 'No',
    }));
    const csvContent = [
      ['Description', 'Amount', 'Category', 'Recurring'],
      ...data.map(row => Object.values(row)),
    ].map(e => e.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'budget_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const data = {
    labels: expenses.map(expense => expense.category),
    datasets: [
      {
        label: 'Expenses by Category',
        data: expenses.map(expense => expense.amount),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
        ],
      },
    ],
  };

  return (
    <Container fluid className="my-5">
      <Helmet>
        <title>Budget Planner</title>
        <meta
          name="description"
          content="Toolifyco offers essential SEO tools and calculators, like PayPal and Stripe fee calculators, to optimize your online strategy."
        />
        <meta
          name="keywords"
          content="Budget Planner, plan the budget, how to plan the budget"
        />
      </Helmet>
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <div className="p-4 shadow bg-light text-dark">
            <div className="title-big-red text-center mb-4">
              <strong>Advanced Budget Planner</strong>
            </div>

            <Form>
              <Form.Label>Monthly Income</Form.Label>
              {incomeSources.map((source, index) => (
                <Row key={index} className="income-row">
                  <Col xs={12} md={6} className="income-col">
                    <Form.Group controlId={`incomeDescription-${index}`}>
                      <Form.Control
                        type="text"
                        value={source.description}
                        onChange={(e) => handleIncomeChange(index, 'description', e.target.value)}
                        placeholder="Income Source Description"
                        className="income-input"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6} className="income-col">
                    <Form.Group controlId={`incomeAmount-${index}`}>
                      <Form.Control
                        type="number"
                        value={source.amount}
                        onChange={(e) => handleIncomeChange(index, 'amount', e.target.value)}
                        placeholder="Amount"
                        className="form-control-lg income-input"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ))}
              <Button className="button-add mt-3" onClick={handleAddIncomeSource}>
                Add Income Source
              </Button>

              <Form.Group controlId="formSavingsGoal" className="mt-3">
                <Form.Label>Savings Goal</Form.Label>
                <Form.Control
                  type="number"
                  value={savingsGoal}
                  onChange={(e) => setSavingsGoal(parseFloat(e.target.value) || '')}
                  placeholder="Enter your savings goal"
                  className="form-control-lg"
                />
              </Form.Group>

              <Button className="button-add mt-3" onClick={() => setShowModal(true)}>
                Add Expense
              </Button>
            </Form>

            <div className="table-responsive mt-4">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Amount</th>
                    <th>Category</th>
                    <th>Recurring</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {expenses.map((expense, index) => (
                    <tr key={index} className="table-row">
                      <td>{expense.description}</td>
                      <td>${expense.amount.toFixed(2)}</td>
                      <td>{expense.category}</td>
                      <td>{expense.recurring ? 'Yes' : 'No'}</td>
                      <td>
                        <Button variant="warning" className="button-edit" onClick={() => handleEditExpense(index)}>
                          Edit
                        </Button>
                        <Button variant="danger" className="button-delete" onClick={() => handleDeleteExpense(index)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <Row className="mt-4">
              <Col xs={12} sm={6} md={4}>
                <div className="p-3 shadow bg-light text-dark">
                  <div className="text-center">
                    <div>Total Income</div>
                    <div>${totalIncome.toFixed(2)}</div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4}>
                <div className="p-3 shadow bg-light text-dark">
                  <div className="text-center">
                    <div>Total Expenses</div>
                    <div>${totalExpenses.toFixed(2)}</div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={6} md={4}>
                <div className="p-3 shadow bg-light text-dark">
                  <div className="text-center">
                    <div>Balance</div>
                    <div>${balance.toFixed(2)}</div>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="mt-4">
              <Pie data={data} />
            </div>

            <Button className="button-export mt-4" onClick={exportData}>
              Export Data
            </Button>
          </div>
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)} className="modalFadeIn">
        <Modal.Header closeButton>
          <Modal.Title>{editIndex !== null ? 'Edit Expense' : 'Add Expense'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="expenseDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                value={expenseDescription}
                onChange={(e) => setExpenseDescription(e.target.value)}
                placeholder="Expense Description"
              />
            </Form.Group>
            <Form.Group controlId="expenseAmount" className="mt-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                value={expenseAmount}
                onChange={(e) => setExpenseAmount(e.target.value)}
                placeholder="Amount"
              />
            </Form.Group>
            <Form.Group controlId="expenseCategory" className="mt-3">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={expenseCategory}
                onChange={(e) => setExpenseCategory(e.target.value)}
              >
                <option>Miscellaneous</option>
                <option>Food</option>
                <option>Utilities</option>
                <option>Entertainment</option>
                <option>Health</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="isRecurring" className="mt-3">
              <Form.Check
                type="checkbox"
                label="Recurring"
                checked={isRecurring}
                onChange={(e) => setIsRecurring(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddExpense}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BudgetPlanner;








